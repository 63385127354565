body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh !important;
  width: 100vw !important;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainMenu {
  padding-top: 10vh;
}

.chatContent {
  // border: ridge 2px;
  padding: 5px 0;
  width: 20em;
  // min-height: 5em;
  overflow: auto;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.chatOptions {
  display: block;
  width: 40%;
  min-width: 200px;
  margin: auto;
  text-decoration: none;
  border-radius: 25px;
  border: 5px solid;
  padding: 20px;
  margin-top: 10px;
  font-size: 24px;
  opacity: 0.9;
  
  &:hover {
    opacity: 1;
    font-weight: bold;
  }
}


#localnetwork {
  .header {
    position: fixed;
    top: 0;
    width: 100vw;
    align-items: center;
    padding: 20px;
    z-index: 9;
    a {
      text-decoration: none;
      font-size: 12px;
      color: inherit;
    }
    .title {
      font-size: 20px;
      font-weight: bolder;
    }
  }

  .chatBody {
    height: 100vh;
    padding-top: 80px;
    padding-bottom: 80px;
    overflow-y: auto;


    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .chatInstance {
    color: white;
    padding: 10px 15px;
    margin: 30px 10px;
    &.left {
      border-radius: 0 20px 20px;
      background-color: #075E54;
    }
    &.right {
      margin-left: auto;
      border-radius: 20px 0 20px 20px;
      background-color: #128C7E;
    }
    textarea {
      font-size: 18px;
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      color: inherit;
      resize: none;
      scrollbar-width: none;
    }
  }
  
  .inputBar {
    position: fixed;
    bottom: 0;
    width: 100%;
    font-size: 18px;
    align-items: end;
    button, input, #messageInput {
      border: none;
      background-color: transparent;
      color: inherit;
      outline: none;
    }
    input, #messageInput {
      border-radius: 25px;
      padding-left: 10px;
      resize: none;
      background-color: rgba(255, 255, 255, 0.058);
      // scrollbar-width: none;
    }
    div, form {
      margin: 10px;
      border-radius: 25px;
      padding: 10px;
      position: relative;
    }
    
    #messageInput {
      margin: 0;
      font-size: 18px;
      text-align: left;
      padding: 5px 10px;

      max-height: 50vh;
      overflow-y: auto;
    }

    .customPlaceholder {
      position: absolute;
      transform: translate(0, 5px);
      left: 20px;
      pointer-events: none;
      color: #ffffff8a;
    }
  }
  
}


#modal {
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .modal-content {
    width: 50%;
    min-width: 300px;
    margin: auto;
    height: 100%;
    background-color: transparent;
    border: none;
  }

  .modal-overlay {
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    position: fixed;
  }
}

.loader-z-index {
  cursor: progress;
  z-index: 99999 !important;
  height: 100%;
  
  .spinner-grow {
    margin: auto;
    // margin-top: 50%;
  }
}


@media(min-width: 424px){
  .loginContainer{
      display: flex;
      /* justify-content: center; */
      /* height: 500px; */
      align-content: center;
      align-items: center;
      // margin: 20px;
      flex-direction: column;
  }
}


.entryContainer{
  margin: auto;
  width: 400px;
  text-align: center;
  font-size: 18px;
  // background-color: #343a40 !important;
  border-radius: 25px;
  * {
    align-self: center;
  //   border-color: white !important;
  //   color: white !important;
  }
  label {
    text-transform: uppercase;
  }

  input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.entryContainer form{
  padding: 10px;
  // color: white;
}

input[type=text], [type=password], [type=email], [type=number] {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  padding: 10px;
}

.redOutLine {
  outline-color: red;
}

.loginInput{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
}

.entryOption {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 25px;
}

.entryOption a {
  text-decoration: none;
  // color: black;
  // border: 2px solid black;
  // background-color: transparent;
  // border-radius: 25px 0 0 0;
  width: 100%;
}

.entryContainer form input[type="button"]{
  text-decoration: none;
  // color: black;
  // border: 2px solid black;
  // background-color: transparent;
  border-radius: 25px;
  width: 100%;
}